<!-- 样式文件在HeadSelect.vue里面 -->
<template>
	<div id="app" style="">
		<Head v-if="weituituiHeadIsShow" ref="Head"></Head>
		<div style="width: 100%;height:50px;"></div>
	<!-- 	<HeadSelect v-if="weituituiHeadSelectIsShow"></HeadSelect> -->
		<div class="topBody">
			<div style="margin-top: 10px;height: 64px;background-color: #ffffff;padding-top: 4px;box-shadow: 0px 15px 10px -15px #e7e7e7;">
				<div class="tab" style="display: flex;">
					<div class="tab_right" style="width: 200px;margin-right: 10px;box-shadow: #ffffff 1px 1px 1px 1px; ">
						<div style="position: absolute;margin-left: 140px;color: #ffff00;background: #000;border-radius: 6px;font-size: 10px;padding: 2px;font-weight: 600;">试运营</div>
						<img src="@/assets/icon/tm.png" style="width: 150px;height: 65px;" /> 
					</div>
					<div style="width: 100%;display: flex;justify-content: left;"> 
						
						<el-menu class="el-menu-demo" :default-active="activeIndex" mode="horizontal" :router="true" 
								background-color="#ffffff" 
								active-text-color="#0092ff" 
								text-color="#606266"
								@select="handleSelect">
						  <el-menu-item index="/" class="menuItemClass">&nbsp;&nbsp;首页&nbsp;&nbsp;</el-menu-item> 
						  <el-menu-item index="/task/task" class="menuItemClass">任务大厅</el-menu-item>
						  <!-- <el-menu-item index="/" class="menuItemClass">任务详情</el-menu-item> -->
						  <el-menu-item index="/system/myuser/index" class="menuItemClass">个人中心</el-menu-item>
						  <el-menu-item index="/message/message" class="menuItemClass">任务社区</el-menu-item>
						  <el-menu-item index="/InviteUsers/InviteUsers" class="menuItemClass">邀请有奖</el-menu-item>
						</el-menu>
						<div class="line"></div>
					</div> 
				</div>
			</div>
			
			<!-- <div style="width: 1200px;margin: auto;height: 60px;background-color: #008000;">
				<nav>
					<router-link target="_blank" to="/">Home1</router-link> |
					<router-link target="_blank" to="/about">About2</router-link>|
					<router-link to="/">Home3</router-link> |
					<router-link to="/about">About4</router-link>|
				</nav> 
			</div> -->
		</div>


		<router-view />
		<Bottomn v-if="weituituiBottomnIsShow"></Bottomn>
		
		<div style="position:fixed; bottom:50px; right:50px;height: 50px;width: 150px;">
			<el-button  type="infor" @click="message()" plain icon="el-icon-chat-dot-round" style="width: 150px;height: 50px;font-size: 20px;">在线客服</el-button>
		</div>	
		
	</div>
</template>
<script>
	import Bottomn from '@/components/Bottomn.vue';
	import Head from '@/components/Head.vue';
	import HeadSelect from '@/components/HeadSelect.vue';

	export default {
		name: 'HomeView',
		components: {
			Bottomn,
			Head,
			HeadSelect
		},
		data() {
			return {
				activeIndex: '/',
				weituituiBottomnIsShow: true,
				weituituiHeadSelectIsShow: true,
				weituituiHeadIsShow: true,
				username111: '1111'
			}
		},
		mounted(){ 
			this.$router.onReady(() => { //特殊  加载完执行 不然获取不到
				//if(''==this.$route.path){
					//this.activeIndex = '/system/myuser/index'
					this.activeIndex = this.$route.path
				//}
				 //alert("==="+this.$route.path)
			});
			// this.$router.onReady(() => { //特殊  加载完执行 不然获取不到  
			//     this.activeIndex = this.$route.path;
			// }); 
		},
		
		methods: {
			message(){
				
				window.open('https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97847504902344cce81ae7e06a5b90ffa874bea9c674d880284e2a86fc3cd9785a6349f0f26cf057748b7cf69384d559&uid=-guoxin-&c1=c111&c2=c222&c3=c333&c4=c444&c5=c555');
			},
			handleSelect(){ 
			},
			//注销用户--退出登录--主页删除本地存储
			closeUserName(){
				window.localStorage.removeItem("Authorization");
				window.localStorage.removeItem("userName");
			},
			fatherMethod(username) {
				 
				//alert("APP.vue==="+username);
				//this.$refs.Head.$emit('childMethod', username) // 方法1:触发监听事件
				//this.$refs.Head.callMethod() // 方法2:直接调用
			},
			setAppHead() {
				//this.$refs.Head.$emit('childMethod', '发送给方法一的数1据'+username) // 方法1:触发监听事件
				this.$refs.Head.headSetUserName() // 方法2:直接调用
			},
			test() {
				this.$router.push({
					path: "/system"
				})
			}
		},
		watch: {
			'$route': {
				handler(path, from) {
					if ('/system/myuser' === path.path || '/' === path.path || '/task/taskItem' === path.path || '/login/login' ==
						path.path || '/register/register' == path.path) {
						this.weituituiBottomnIsShow = true;
						this.weituituiHeadSelectIsShow = true;
						this.weituituiHeadIsShow = true;
					} else if ('/task/task' === path.path) {
						this.weituituiBottomnIsShow = true;
						this.weituituiHeadSelectIsShow = true;
						this.weituituiHeadIsShow = true;
					}else if(path.path.indexOf('myuser') != -1){
						this.weituituiHeadIsShow = true;
						this.weituituiHeadSelectIsShow = true;
					}else if(path.path.indexOf('admin') != -1){
						this.weituituiHeadIsShow = true;
						this.weituituiHeadSelectIsShow = true;
					}else if('/message/message'=== path.path){
						this.weituituiBottomnIsShow = true;
						this.weituituiHeadSelectIsShow = true;
						this.weituituiHeadIsShow = true;
					}else if('/findPwd/findPwd'=== path.path){
						//找回密码
						this.weituituiBottomnIsShow = true;
						this.weituituiHeadSelectIsShow = true;
						this.weituituiHeadIsShow = true;
					}else if('inviteUsers/InviteUsers'===path.path){
						this.weituituiBottomnIsShow = true;
						this.weituituiHeadSelectIsShow = true;
						this.weituituiHeadIsShow = true;
					} else {
						this.weituituiBottomnIsShow = true;  
					}
				}
			}
		}
	}
</script>
<style>
	.el-menu--horizontal > .el-menu-item {
		font-size: 16px;
	}
	.menuItemClass{
		font-weight: 600; letter-spacing:5px;
	} 
	
	.tab_left_index0 {
		font-weight: 600;
		height: 100%;
		text-align: left;
		line-height: 60px;
		width: 140px;
		background: #FFF;
		/* background: #ff0000; */
		padding-left: 25px;
		color: #000000;
		font-size: 16px;
	}

	body,
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		/* background-color: #f4f5f9; */
		background-color: #ffffff;
		margin: 0;
		padding: 0;
	}

	nav {
		padding: 30px;
	}

	.tab_left_index0.router-link-exact-active {
		background: #ff00ff;
		color: #FFF;
	}

	nav a.router-link-exact-active {
		color: red;
		background-color: #FFF;
	}
</style>
