<template>
  <div class="home"> 
	 
	<IndexTask/>
	<JiaoYiLiuCheng/>
	<YouQingLianJie/>
	<!-- <Head></Head> -->
  </div>
</template>

<script> 
import Head from       '@/components/Head.vue' 
import IndexTask from '@/components/task/IndexTask.vue'
import JiaoYiLiuCheng from '@/components/task/JiaoYiLiuCheng.vue'
import YouQingLianJie from '@/components/task/YouQingLianJie.vue'
export default {
  name: 'HomeView',
  components: {
   
	Head,
	 
	IndexTask,
	JiaoYiLiuCheng,
	YouQingLianJie
  }
}
</script>
