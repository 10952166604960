<template>
	<div>
		<div class="head" style="position: fixed;top: 0;margin-bottom: 50px;background: #fafafa;z-index: 11;box-shadow:1px 0px 10px #e3e3e3;">
			<div class="headInfo" style="background: #ffffff;">
				<div class="left">
					<!-- <div class="leftTxt" style="color: #303133;">
						微推推
					</div> -->
					<div class="leftTxt" style="color: #303133;font-weight: 600;">
						<!-- 发布广告任务&nbsp;上微推推威客任务网&nbsp;提供最专业网络推广服务 -->
						&nbsp;&nbsp;微&nbsp;推&nbsp;推&nbsp;任&nbsp;务&nbsp;威&nbsp;客&nbsp;网
					</div>
				</div>
				<div class="right" style="width: 200px;background: #ffffff;height: 50px;" v-if="headBoolean">
					<!-- <router-link to="/login/login" style=" text-decoration: none">
						<div class="right1 shou" @tap="$goBack(2,'/pages/login/login')">
							登录
						</div>
					</router-link>
					<router-link to="/register/register" style=" text-decoration: none">
						<div class="right2 shou" @tap="$goBack(2,'/pages/reg/reg')">
							注册
						</div>
					</router-link> -->
					<el-menu router :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					 background-color="#ffffff" text-color="#303133" active-text-color="#0092ff">
						<el-menu-item index="/login/login" style="height: 50px;line-height: 50px;width: 100px;text-align: center;">登录</el-menu-item>
						<el-menu-item index="/register/register" style="height: 50px;line-height: 50px;width: 100px;text-align: center;">快速注册</el-menu-item>
					</el-menu>

				</div>
				<div class="right" style="width: 380px;" v-else>
					<el-menu router :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect"
					 background-color="#ffffff" text-color="#303133" active-text-color="#0092ff">
						<!-- <el-submenu index="1" popper-class="heihei" :popper-append-to-body="false"> -->
						<el-submenu index="1">
							<template slot="title">接单中心</template>
							<el-menu-item index="/system/myuser/SignUp">已报名的任务</el-menu-item>
							<el-menu-item index="/system/myuser/MyJoinTask">我参与的任务</el-menu-item>
						 
						</el-submenu>
						<el-submenu index="2">
							<template slot="title">发单中心</template>
							<el-menu-item index="/system/myuser/PostTask">我要发布任务</el-menu-item>
							<el-menu-item index="/system/myuser/MyPostTask">我发布的任务</el-menu-item> 
						</el-submenu>
						<el-submenu index="3">
							<template slot="title">{{testfile(userName)}}</template>
							<el-menu-item index="/system/myuser/index">个人中心</el-menu-item>
							<el-menu-item index="/system/myuser/userInfo">修改密码</el-menu-item>
							<el-menu-item @click="tuichudenglu">退出登录</el-menu-item>
						</el-submenu>
						<!-- <el-menu-item index="3" disabled>消息中心</el-menu-item>
					   <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
					</el-menu>
					<div class="line"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		encrypt,
		decrypt
	} from '@/utils/jsencrypt.js'
	export default {
		mounted() {
			//this.monitoring() // 注册监听事件
		},
		data() {
			return {
				headBoolean: true,
				userName: '', //用户名
				activeIndex: '1', //登录注册的
				activeIndex2: '1' //接单和发单和账号的
			};
		},
		created() {
			this.getInitUserName();
		},
		methods: {
			href(path) {
				this.$router.push({ //核心语句
					path: path, //跳转的路径
					// query: { //路由传参时push和query搭配使用 ，作用时传递参数
					// 	id: "weituitui",
					// }
				})
			},
			tuichudenglu() {
				this.$confirm('您确定要退出当前登录账户：【' + this.userName + '】登录吗?', '退出登录提示', {
					confirmButtonText: '我不退出登录',
					cancelButtonText: '我要退出登录',
					type: 'warning'
				}).then(() => {
					// this.$message({
					//   type: 'info',
					//   message: '删除成功!'
					// });
					//>>>>>>>>>>>不退出系统不提示任何消息
				}).catch(() => {
					this.$message({
						type: 'success',
						message: '您已成功退出登录！'
					});
					this.headBoolean=true;
					this.$parent.closeUserName();
				});

			},
			// monitoring(username) { // 监听事件
			// 	this.$on('childMethod', (res) => {
			// 		alert('方法1:触发监听事件监听成功'+username)
			// 		alert(res);

			// 	})
			// },
			headSetUserName() {
				this.getInitUserName();
			},
			//初始化左上角用户名
			getInitUserName() {
				var userName = window.localStorage['userName'];
				if (typeof userName === 'undefined') {
					return
				}
				this.userName = decrypt(userName);
				this.headBoolean = false;
			},
			handleSelect(key, keyPath) {
				console.log(key, keyPath);
			},
			//处理账户中间代替型号
			testfile(value) {
				const start = value.slice(0, 3)
				const end = value.slice(-4)
				if (value.trim().length >= 11) {
					return `${start}******${end}`
				} else {
					return `${start}****`
				}

			}
		}
	}
</script>
<style>
	.right2 {
		width: 76px;
		height: 100%;
		text-align: center;
		line-height: 50px;
		color: #fff;
		font-size: 14px;
	}

	.right1 {
		width: 76px;
		height: 100%;
		text-align: center;
		line-height: 50px;
		color: #303133;
		font-size: 14px;
	}

	.leftTxt {
		height: 50px;
		height: 100%;
		text-align: center;
		line-height: 50px;
		padding-left: 10px;
		padding-right: 10px;
		color: #303133;
		font-size: 14px;
	}

	.right {
		width: 150px;
		height: 100%;
		display: flex;
	}

	.leftTxt {
		height: 50px;
		height: 100%;
		text-align: center;
		line-height: 50px;
		padding-left: 10px;
		padding-right: 10px;
		color: #fff;
		font-size: 14px;
	}

	.left {
		flex: 1;
		display: flex;
	}

	.headInfo {
		width: 1200px;
		height: 100%;
		margin: auto;
		display: flex;
	}

	.headInfo {
		width: 1200px;
		height: 100%;
		margin: auto;
		display: flex;
	}

	.head {
		height: 50px;
		width: 100%;
		
	 
		min-width: 1200px;
	}

	.shou {
		cursor: pointer;
	}
</style>
