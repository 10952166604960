export default {
	// 开发环境
	dev: {
		baseUrl: "http://192.168.1.8:28080" //开发环境公共地址
	},
	 
	//线上接口
	prod: {
		baseUrl1: "http://192.168.1.8:8888" //线上环境公共地址
	}
};
