import JSEncrypt from 'jsencrypt/bin/jsencrypt.min'

// 密钥对生成 http://web.chacuo.net/netrsakeypair

const publicKey ='MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDBQB8RYS/nsUwf0FldFAEyxtDAsGTDl+iRqkrXY39pLf19Gv937Lx6EaSvRE228jGgzvJSdtK2CapnUdiq/qfOh+vQHHjpyPmnKC2bzwYR9ukjaS32PpPME+zFTKlc0kqxl6S4L/v4Yrz9aS3i+As7SMimZG7gc1zWVIKJxwYUxQIDAQAB';

const privateKey ='MIICdQIBADANBgkqhkiG9w0BAQEFAASCAl8wggJbAgEAAoGBAIRxtihywaIX6VttgF99gOofQsFpcRN8brLiNBuVBisCV1gBGx3fpvfikIPd7UoTtvuiRwOWFNG597wWJ4FnDO08BSO+NPqXZgsxt5iv9FvAHWWft7mdOA5KGcqaw37poouT1mo2gyC78C23WqJZYpVGGaMoboDCozeF+/rwW4b3AgMBAAECgYBjYt05rxuk4mKnzB0ICyrhBUJX1yMWZ0q+i8ZkaEjvn1sh2/qYSUuHOooNmkzFSNQIMjct9sJ6ux2vE8TjcwwcKHcfZaoW4CvLotkPpu5gBAt9MZseiARpL0i+lQg2iBuFpq8vsmhBnOIGrwFS2YFxBaOe9yrKE18ac9zn6EpReQJBAMldWbv1wyvZYHqwrF0+uSzuCESMV/bFYz8PaCO7PYTQXvnSejnu49u3AnYw85xOrO1vCkSYA6ybRchMS1X8mKUCQQCoYTFz++gqWPDpoYs/CbLTAbd1Cf4plvhV6UihoTR3i3gzG/VvBYo/0t+NGakva3CquN4TuWovJCysHFihAbJrAkEAwPk7Q8wUjHM36JxjssCniY1cUwUET7N+lzJWpNeN7zgZrOSuydJMf4V1UHs9GzCyvKBLgM0FyGbNi1TCZdc7GQI/OzHwMNZeyIR8LaadTYwbUhNIa90XfYeVxp486pXB3EUr/MvXfTB1afq4nOJomEMtWNelO0n0Cp6tFdThU7lFAkAun2zCzyYHLcT/4aTkBYxBAhYeUfaG0s6cVlC/+wZIB41mkc4X4T4GO0mbHdN4DnLTzM/bD5GZir15rJTRJIBh';
	

// 公钥加密数据--------公钥加
export function encrypt(txt) {
	const encryptor = new JSEncrypt()
	encryptor.setPublicKey(publicKey) // 设置公钥
	return encryptor.encrypt(txt) // 对数据进行加密
}
// 私钥解密数据--------私钥解
export function decrypt(txt) {
	const encryptor = new JSEncrypt()
	encryptor.setPrivateKey(privateKey) // 设置私钥
	return encryptor.decrypt(txt) // 对数据进行解密
}
 


