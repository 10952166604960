import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
	  userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : '',
	  id: localStorage.getItem('id') ? localStorage.getItem('id') : '',
  },
  getters: {
	  
  },
  mutations: {
	  // 修改token，并将token存入localStorage
	  changeLogin(state, user) {
	  	state.Authorization = user.Authorization;
	  	state.userName = user.userName;
		state.id = user.id;
	  	
	  	localStorage.setItem('Authorization', user.Authorization);
	  	localStorage.setItem('userName', user.userName);
	  	localStorage.setItem('id', user.id);
	  }
  },
  actions: {
	  
  },
  modules: {
	  
  }
})
