import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

//（设置每个页面的title动态文字）https://blog.csdn.net/zluz_/article/details/120476555

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
	meta:{
		title:"微推推，悬赏任务,悬赏平台,悬赏赚钱,免费发布任务,网赚项目,网赚论坛,威客网站,活动线报,软件源码,代理加盟,商品货源,淘宝刷单,投资理财,担保交易,微推推，微推推是雇主和接单人最值得信赖的免费发布悬赏网赚平台,任务包括(淘宝刷单、商品货源、软件源码、商标设计、logo设计、软文写作等);雇主赏金一呼百应,万里挑一总有一个能够帮到您;接单人利用工作之余或者空闲时间赚些外快,为家庭经济添砖加瓦,最终达到互利共赢的目标"
	}
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
	meta:{
		title:"临时发放任务"//不知道这个
	}
  },
  {
    path: '/task/task',
    name: 'task',
    component: () => import(/* webpackChunkName: "about" */ '../views/task/task.vue'),
	meta:{
		title:"微推推任务大厅"
	}
  },
  {
    path: '/task/taskItem',
    name: 'taskItem',
    component: () => import(/* webpackChunkName: "about" */ '../views/task/taskItem.vue'),
	meta:{
		title:""//任务详情
	}
  },
  {
    path: '/login/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/login.vue'),
	meta:{
		title:"登录微推推"
	}
  },
  {
    path: '/register/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register/register.vue'),
	meta:{
		title:"快速注册微推推"
	}
  },
  {
    path: '/message/message',
    name: 'message',
    component: () => import(/* webpackChunkName: "about" */ '../views/message/message.vue'),
	meta:{
		title:"微推推最新消息"
	}
  },
  
  {
    path: '/inviteUsers/InviteUsers',
    name: 'message',
    component: () => import(/* webpackChunkName: "about" */ '../views/inviteUsers/InviteUsers.vue'),
  	meta:{
  		title:"微推推邀请有奖"
  	}
  },
  {
    path: '/findPwd/findPwd',
    name: 'findPwd',
    component: () => import(/* webpackChunkName: "about" */ '../views/findPwd/findPwd.vue'),
  	meta:{
  		title:"微推推找回密码"
  	}
  },
  // {
  //   path: '/system',
  //   name: 'system',
  //   //component: () => import(/* webpackChunkName: "about" */ '../views/system/index.vue'),
  // 	children:[
  // 		{
  // 			path:'index',
  // 			component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/index.vue'),
  // 		},
		// {
		// 	path:'myuser',
		// 	component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/myuser.vue'),
		// }
  // 	]
  // },
  
  {
    path: '/system/admin/',
    name: 'admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/system/index.vue'),
  	children:[
  				{
  					path:'postTask',
  					component: () => import(/* webpackChunkName: "about" */ '../views/system/admin/postTask.vue'),
  					name:'system_admin_postTask',
  					meta:{
  						activeMenu:'/system/admin/',
  						title:"任务审核"
  					}
  				}
  	]
  },
  
  {
    path: '/system/myuser/',
    name: 'myuser',
    component: () => import(/* webpackChunkName: "about" */ '../views/system/index.vue'),
  	children:[
				{
					path:'index',
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/index.vue'),
					name:'system_myuser_index',
					meta:{
						activeMenu:'/system/myuser/',
						title:"我的主页"
					}
				},
				{
					path:'myuser',
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/myuser.vue'),
					name:'system_myuser_myuser',
					meta:{
						activeMenu:'/system/myuser/'
					}
				},
				{
					path:'userInfo',//基本资料
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/userInfo.vue'),
					name:'system_myuser_userInfo',
					meta:{
						activeMenu:'/system/myuser/',
						title:"基本资料"
					}
				},
				{
					path:'msg',//站内消息
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/msg.vue'),
					name:'system_myuser_msg',
					meta:{
						activeMenu:'/system/myuser/',
						title:"站内消息"
					}
				},
				{
					path:'PostTask',//发布任务
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/PostTask.vue'),
					name:'system_myuser_PostTask',
					meta:{
						activeMenu:'/system/myuser/',
						title:"发布任务"
					}
				},
				{
					path:'MyPostTask',//我发布的任务
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/MyPostTask.vue'),
					name:'system_myuser_MyPostTask',
					meta:{
						activeMenu:'/system/myuser/',
						title:"我发布的任务"
					}
				},
				{
					path:'MyJoinTask',//我参加的任务
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/MyJoinTask.vue'),
					name:'system_myuser_MyJoinTask',
					meta:{
						activeMenu:'/system/myuser/',
						title:"我参加的任务"
					}
				},
				{
					path:'SignUp',//已报名的任务
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/SignUp.vue'),
					name:'system_myuser_SignUp',
					meta:{
						activeMenu:'/system/myuser/',
						title:"已报名的任务"
					}
				},
				{
					path:'ToPay',//去充值
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/ToPay.vue'),
					name:'system_myuser_ToPay',
					meta:{
						activeMenu:'/system/myuser/',
						title:"我要充值"
					}
				},
				{
					path:'Withdraw',//提现
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/Withdraw.vue'),
					name:'system_myuser_Withdraw',
					meta:{
						activeMenu:'/system/myuser/',
						title:"我要提现"
					}
				},
				{
					path:'Finance',//财务中心
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/Finance.vue'),
					name:'system_myuser_finance',
					meta:{
						activeMenu:'/system/myuser/',
						title:"财务中心"
					}
				},
				{
					path:'Promoter',//推广员
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/Promoter.vue'),
					name:'system_myuser_Promoter',
					meta:{
						activeMenu:'/system/myuser/',
						title:"我是推广员"
					}
				},
				{
					path:'PromoterIncome',//推广员收入
					component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/PromoterIncome.vue'),
					name:'system_myuser_PromoterIncome',
					meta:{
						activeMenu:'/system/myuser/',
						title:"推广收入"
					}
				},
  		// {
  		//   path: 'myuser',
  		//   name: 'myuser',
  		//   component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/index.vue'),
  		// 	children:[
  		// 		{
  		// 			path:'index',
  		// 			component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/index.vue'),
  		// 		},
  		// 		{
  		// 			path:'myuser',
  		// 			component: () => import(/* webpackChunkName: "about" */ '../views/system/myuser/myuser.vue'),
  		// 		}
  		// 	]
  		// }
		
  	]
  }
   
]

 
 
const router = new VueRouter({
	mode: 'history',//注释掉本行 地址栏没有#号
	routes
})

export default router
