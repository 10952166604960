<template>
	<div style="background-color: #f8f8f8;overflow: hidden;"> 
		<div class="banner" style="display: flex;height: 300px;margin-top: 10px;">
			<div style="width: 800px;border-radius: 10px;">
				<div>
					<el-carousel height="220px" style="width: 800px;border-radius: 10px">
						  <!-- 动态请求不收录 -->
					      <!-- <el-carousel-item v-for="item in bannerPath" style="width: 800px;border-radius: 10px">
					        <img class="bannerImg" :src="item.path" @click="goBanner(item)"  style="width: 800px;border-radius: 10px;"></img>  
					      </el-carousel-item> -->
						  <el-carousel-item style="width: 800px;border-radius: 10px">
						    <img class="bannerImg" src="../../assets/banner/static20240219/1.png" style="width: 800px;border-radius: 10px;"></img>  
						  </el-carousel-item>
						  <el-carousel-item  style="width: 800px;border-radius: 10px">
						    <img class="bannerImg" src="../../assets/banner/static20240219/2.png" style="width: 800px;border-radius: 10px;"></img>  
						  </el-carousel-item>
						  <el-carousel-item style="width: 800px;border-radius: 10px">
						    <img class="bannerImg" src="../../assets/banner/static20240219/3.png" style="width: 800px;border-radius: 10px;"></img>  
						  </el-carousel-item>
					</el-carousel>
				</div>
				<div>
					<div class="infoList" style="width: 800px;">
						<div class="infoList_logo">
							<img src="../../assets/icon/liebiao/liebiao.png" class="infoList_logo_img" />
						</div>
						<div class="infoList_txt">
							悬赏任务信息
						</div>
					</div>
				</div>
			</div>
			<div>
				<div style="flex: 1;margin-left: 10px;">
					<div style="height: 40px;display: flex;background:#ffffff ;border-top-left-radius: 10px;border-top-right-radius: 10px;">
						<div style="color: #303133;line-height: 40px;padding-left: 22px;flex: 1;font-weight: 600;font-size: 13px;text-align: center;">
							 微推推公告
						</div>
						<!-- <div style="line-height: 40px;color: #606266;font-size: 13px;text-align: right;padding-right: 10px;cursor: pointer;">
							 更多
							 <li class="el-icon-d-arrow-right"></li>
						</div> -->
					</div>
					<el-table  :show-header="false" stripe :data="msgData"  style="width:380px;overflow: auto;height: 260px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;" size="small" highlight-current-row>
						<el-table-column
						      type="index"
						      width="50">
						    </el-table-column>
						<el-table-column  show-overflow-tooltip prop="title" label="" align="center">
						</el-table-column>  
					</el-table> 
				</div>
			</div>
		</div>
		<!-- <div class="banner" style="display: flex;border:red solid 1px;height: 220px;margin-top: 10px;"> 
			<div  style="width: 800px;border-radius: 10px;"> 
				  <el-carousel height="220px" style="width: 800px;border-radius: 10px">
				        <el-carousel-item v-for="item in bannerPath" style="width: 800px;border-radius: 10px">
				          <img class="bannerImg" :src="item.path" style="width: 800px;border-radius: 10px;"></img>  
				        </el-carousel-item> 
				  </el-carousel>
			</div>
			<div style="flex: 1;margin-left:10px;border:green solid 1px">
				<div class="txt" style=""> 
					 <div style="height: 40px;display: flex;background:#fbfbfc ;">
						 <div style="color: #303133;line-height: 40px;padding-left: 22px;flex: 1;font-weight: 600;font-size: 13px;">
							 通知
						 </div>
						 <div style="line-height: 40px;color: #606266;font-size: 13px;text-align: right;padding-right: 10px;cursor: pointer;">
							 更多
							 <li class="el-icon-d-arrow-right"></li>
						 </div>
					 </div>
					 <div style="max-height: 260px;overflow: auto;">
						<el-table :show-header="false" stripe :data="msgData"  style="" size="small" highlight-current-row> 
							<el-table-column show-overflow-tooltip prop="nick_name" label="" align="center">
							</el-table-column>  
						</el-table> 
					 </div>
				</div>
			</div> 
		</div> -->
		
		<!-- <div class="infoList">
			<div class="infoList_logo">
				<img src="../../assets/icon/liebiao/liebiao.png" class="infoList_logo_img" />
			</div>
			<div class="infoList_txt">
				悬赏任务信息
			</div>
		</div> -->
		<div class="renwu" style="background-color:#f4f5f9 ;border-radius: 10px;margin-bottom: 10px;">
			<div class="leftRw" style="border-radius: 10px;margin: 0 6px 0 0;">   
				<el-table stripe
					size="small"
				    ref="singleTable"
				    :data="tableData"
				    highlight-current-row
					border
				    style="width: 100%;border-radius: 10px;">
				    <el-table-column
				      type="index"
				      width="50">
				    </el-table-column>
				    <el-table-column show-overflow-tooltip property="date" label="任务标题">
					  <template slot-scope="scope">
					  	<el-link type="primary"  :href="getHref(scope.row)" style="" target="_blank" style="font-weight: 600;">{{scope.row.title}}</el-link>
					  </template>
				    </el-table-column>
				    <el-table-column property="name" label="所需稿件数" width="82">
						<template slot-scope="scope">
							{{scope.row.total}}个
						</template>
				    </el-table-column>
				    
					<el-table-column property="address" width="82" label="剩余稿件数">
						<template slot-scope="scope">							
							<!-- {{scope.row.total}}个 -->
							{{scope.row.total-scope.row.wtt_task_registration_counts}}个
						</template>
					</el-table-column>
					<el-table-column property="address" width="70" label="每单单价">
						<template slot-scope="scope">
							{{scope.row.unit_price}}元
						</template>
					</el-table-column>
					<el-table-column property="address" width="70" label="做单次数">
						<template slot-scope="scope">
							{{0==scope.row.limits?'多次':"一次"}}
						</template>
					</el-table-column>
					<el-table-column property="address" label="任务状态" width="70">
						<template slot-scope="scope"> 
						
							<el-tag v-if="1==scope.row.taskState" size="medium" class="">进行中</el-tag>
							<el-tag type="danger" v-else-if="2==scope.row.taskState" size="medium" class="">已到期</el-tag>
							<el-tag type="success" v-else-if="5==scope.row.taskState" size="medium" class="">评标中</el-tag>
							<el-tag type="info" v-else-if="7==scope.row.taskState" size="medium" class="">已结束</el-tag>
							<el-tag type="info" v-else size="medium" class="">未知状态</el-tag>
						
							<!-- <el-tag  type="info" size="mini" class="">{{getTaskState(scope.row)}}</el-tag> -->
						</template>
					</el-table-column>
					<el-table-column prop="endTime" label="结束时间" align="center" width="136">
					</el-table-column>
				  </el-table>
				  
				  <div style="width: 100%;height: 50px;text-align: right;  overflow: hidden;">
				  	<div style="width: 100%;height:34px;  margin-top: 8px;">
				  		<el-pagination
				  			@size-change="handleSizeChange"
				  			@current-change="handleCurrentChange"
				  			:current-page="currentPage"
				  			:page-sizes="[15,30, 45, 60]"
				  			:page-size="15"
				  			layout="total, sizes, prev, pager, next, jumper"
				  			:total="total" 
				  		 >
				  		</el-pagination>
				  	</div>
				  </div>
				  
			</div> 
			<div class="rightRw" style="background: #ffffff;overflow: hidden;border-radius: 6rpx;margin-left: 4px;border-radius: 10px;">
				<div class="rightRw_top" style=" ">
					<!-- <img class="rightRw_top_img" src="../../assets/zuixinchengjiao/zuixinchengjiao.jpg"/> -->
					<img class="rightRw_top_img" src="../../assets/zuixinchengjiao/zuixinchengjiao20240219.png"/>
				</div>
				<div class="rightRw_top_center">
					<div class="rightRw_top_center_info" v-for="(item,i) in getIndexTaskData" style="cursor: pointer;" @click="goTask(item.task_id)">
						<div class="rightRw_top_center_info1">
							<div class="lienhright">
								•
							</div>
							<div class="lienhright fountColor fountSize padding-left-right">
								恭喜
							</div>
							<div class="lienhright colorFount fountSize padding-left-right">
								{{item.username_my}}
							</div>
							<div class="lienhright fountColor fountSize padding-left-right">
								{{item.type_start}}
							</div>
							<div class="lienhright colorFount fountSize padding-left-right">
								{{item.username}}
							</div>
							<div class="lienhright fountColor fountSize padding-left-right">
								{{item.type_end}}
							</div>
						</div>
						<div class="rightRw_top_center_info1">
							<div class="rightRw_top_center_info1_1">
								<div class="st11">
									<img class="st1Img" src="../../assets/icon/renminbi/rmb.png"></image>
								</div>
								<div class="st22">
									{{item.price}}
								</div>
								<div class="st33 shou">
									{{item.title}}
								</div>
							</div>
							<div class="rightRw_top_center_info1_2 fountColor">
								{{item.yzCount}}人已赚|剩余:{{item.zrwTotal-item.yzCount}}个
							</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>
<script>
import {getBanner,getTodyMsgList,getIndexTaskListData,getIndexTaskList } from "../../http/api"
	export default {
		name: 'myuser',
		components: {

		},
		data() {
			return { 
				getIndexTaskData:[],//首页客户端最新消息100条数据
				bannerPath:[
				],				 
				msgData:[ 
				],
				
				 tableData: [],
				 currentRow: null,
				 gonggaoData: [],
				 requestParam:{
				 	taskTitle:'',//任务标题
				 	taskTypeId:''  ,//任务分类id
				 	unitPriceStart:'',//稿件单价起始金额
				 	unitPriceEnd:'',//稿件单价结束金额
				 	limits:'-1',//接单限制 0和1
				 	taskTime:'',//任务时间范围
				 	taskState:1,//任务状态
				 	pageNum: 1,
				 	pageSize: 15,
				 },
				 total:0,//总条数
				 currentPage: 1,
			}
		},
		created() {
			this.init();
		},
		mounted() {

		},
		methods: {
			//任务状态处理
			getTaskState(row){
				if(1==row.taskState){
					return '进行中';
				}else if(2==row.taskState){
					return '已到期';
				}else if(5==row.taskState){
					return '评标中';
				}else if(7==row.taskState){
					return '已结束';
				}else{
					return '未知状态';
				} 
			},
			//处理超链接
			getHref(row){
				return '/task/taskItem?id='+row.id
			},
			//分页固定函数
			handleSizeChange(val) {
			  console.log(`每页 ${val} 条`);
			  this.requestParam.pageSize = val
			  this.getIndexTaskList();
			},
			handleCurrentChange(val) {
			  console.log(`当前页: ${val}`);
			  this.requestParam.pageNum = val
			  this.getIndexTaskList();
			},
			//最新消息里面的任务跳转
			goTask(taskId){ 
				window.open('/task/taskItem?id='+taskId, '_blank');
			},
			//banner处理是否跳转
			goBanner(data){ 
				if(0==data.bannerType){ 
					window.open(data.url, '_blank');
				} 
			},
			 init(){
				 this.getBanner();
				 this.getTodyMsgList();
				 this.getIndexTaskListData();
				 this.getIndexTaskList();//加载任务
			 },
			 //获取任务
			 getIndexTaskList(){
				 getIndexTaskList(this.requestParam).then(response =>{
					 console.log(JSON.stringify(response));
					 this.tableData=response.data.data;
					 this.total=response.data.total;
				 })
			 },
			 //获取最新消息100条数据
			 getIndexTaskListData(){
				 getIndexTaskListData().then(result =>{
					 //console.log(JSON.stringify(result));
					 this.getIndexTaskData=result.data;
				 })
			 },
			 //获取今日动态数据数组
			 getTodyMsgList(){
				 getTodyMsgList().then(result =>{ 
					 this.msgData=result.data;
				 })
			 },
			 //获取banner数据数组
			 getBanner(){
				 getBanner().then(result =>{
					 this.bannerPath=result.data 
				 })
			 }
		}
	}
</script>

<style>
	.rightRw_top_center_info:hover{
		background-color: #f8f8f8;
	}
	.rightRw_top_center {
		/* //background: yellow; */
		overflow: auto;
		max-height: 750px;
	}
	.infoList { 
		height: 75px;
		margin: auto; 
		
		display: flex;
	}
	.leftRw {
		width: 806px;
		/* display: flex; */
		flex-wrap: wrap;
		
		/* justify-content: space-between; */
	}
	.renwu {
		width: 1200px;
		margin: auto;
		min-width: 1200px;
		max-width: 1200px;
		margin-top: 10px;
		display: flex;
		background: #FFF;
		/* height: 626px; */
		min-height: 626px;
	}
</style>
