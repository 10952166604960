import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store' 
import axios from 'axios'
import ElementUI from 'element-ui' //手动变红     
import '../node_modules/element-ui/lib/theme-chalk/index.css'	//手动变红   
Vue.use(ElementUI)	//手动变红


//axios.defaults.baseURL = 'http://127.0.0.1:28080'
//axios.defaults.baseURL = "http://weituitui.com.cn/dev-api/";

Vue.config.productionTip = false

router.beforeEach((to,from,next) =>{
	if(to.meta.title){
		document.title = to.meta.title
	}
	next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


