import axios from "axios";
import env from "./env"; 
import router from '../router'
import {
	Message,MessageBox, Notification
} from 'element-ui'
//这里是私有域名  但是也可以不写
var vipUrl = "/dev-api-pc";
// 创建axios实例

var baseUrl=vipUrl+env.dev.baseUrl

axios.defaults.baseURL="http://weituitui.com.cn/dev-api-pc";//线上环境
//axios.defaults.baseURL="http://localhost:28080";//本地*****环境


const service = axios.create({
	//这里拿线上接口测试
	//baseUrl: "http://weituitui.com.cn/dev-api-pc/",
	//baseURL: "http://localhost:28080",
	headers: {
		"Authorization": localStorage.getItem('Authorization')
	}, //请求头
	settimeout: 4000, //超时时间
});

// 添加请求拦截器
service.interceptors.request.use(
	config => {
		// 在发送请求之前做些什么
		config.headers["deviceType"] = "H5000";
		config.headers["Authorization"] = localStorage.getItem('Authorization');
		console.log("请求的数据:", config);
		return config;
	},
	error => {
		// 对请求错误做些什么
		return Promise.reject("出错", error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	response => {
		// 对响应数据做点什么
		// console.log("返回的数据", response);
		if (response.data.code == 200) {
			//后台返回200，去掉双层data，否则全部显示
			if(response.data.data){
				return Promise.resolve(response.data);
			}else{
				//否则全部显示原来的数据
				return Promise.resolve(response);
			}
		}else if(response.data.code == 401){
			
			
			router.replace({
			  path: '/login/login',
			  // 登录成功后跳入浏览的当前页面
			  query: {redirect: router.currentRoute.fullPath}
			});
			return;
			 // MessageBox.confirm(response.data.msg, '登录信息提示', {
			 //       confirmButtonText: '重新登录',
			 //       cancelButtonText: '取消',
			 //       type: 'warning'
			 //     }).then(() => {
					 
			 //        router.replace({
			 //          path: '/login/login',
			 //          // 登录成功后跳入浏览的当前页面
			 //          query: {redirect: router.currentRoute.fullPath}
			 //        });
				// 	return;
			 //     }).catch(() => {
			 //       this.$message({
			 //         type: 'info',
			 //         message: '已操作取消'
			 //       });          
				//    return;
			 // });
			
			
			 
			// Message.error({
			// 	message: '3秒后自动跳转登录'
			// });
			// $.messager.confirm("操作提示", "确定要操作？", function (data) {
			//     if (data) {
			// 		  //do 确定
			//      }else {
			// 		//do 取消    	
			//      }
			// });
			//Message.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
			//    confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning'
			//}).then(() => {
			    // isRelogin.show = false
			    // store.dispatch('LogOut').then(() => {
			    //     location.href = '/#/login/login'
			    // })
			///}).catch(() => {
			    //isRelogin.show = false
			//})
			//alert("code="+response.data.code+"---msg="+response.data.msg);
			
		}else if(response.data.code == 500){
			Message.error({
				message: '系统快要处理不过来了，请刷新后继续尝试>>提示：'+response.data.msg
			});
		}else{
			return Promise.resolve(response);
		}
		//return response;
	},
	error => {
		// 对响应错误做点什么
		// if(error.response.code){
			
		// }else{
		// 	alert("error=="+error)
		// }
		Message.error({
			message: '网络异常，请刷新后继续尝试'
		});
		return Promise.reject(error);
	}
);
export default service;
