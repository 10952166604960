//引入request.js文件
import request from "./request";
//汇总信息（全部接口60个-70个）20220921001959

export function getAdminIsButton(){
	return request({
		url: "/weituitui/system/task/getAdminIsButton", 
		method: "post"
	});
}

//[66]雇主点击任务结算  20230104
export function taskSettlement(data){
	return request({
		url: "/weituitui/system/user/taskSettlement", 
		method: "post",
		data: data  
	});
}

//[65]稿件投诉记录列表数据  雇主身份下的数据  1211
export function getUserTaskDataInfoLists(data){
	return request({
		url: "/weituitui/system/task/getUserTaskDataInfoLists", 
		method: "post",
		data: data  
	});
}

//[64]我参与的任务 不合格的稿件发起投诉【雇主方】 1210
export function taskDataComplaints(data){
	return request({
		url: "/weituitui/system/task/taskDataComplaints", 
		method: "post",
		data: data  
	});
}

//[63]稿件投诉记录列表数据  提交稿件身份下的数据  1209
export function getUserTaskDataInfoList(data){
	return request({
		url: "/weituitui/system/task/getUserTaskDataInfoList", 
		method: "post",
		data: data  
	});
}

//[62]我参与的任务 不合格的稿件发起投诉 1208
export function taskDataComplaint(data){
	return request({
		url: "/weituitui/system/task/taskDataComplaint", 
		method: "post",
		data: data  
	});
}

//[61]我的主页统计 1201
export function getSystemIndex(data){
	return request({
		url: "/weituitui/system/task/getSystemIndex", 
		method: "post",
		data: data  
	});
}


//[60]支付宝充值 1115
export function aliPCpay(data){
	return request({
		url: "/weituitui/system/aliPCpay", 
		method: "post",
		data: data  
	});
}

//[59]推广收入情况列表 1018
export function getTgUserSQList(data){
	return request({
		url: "/weituitui/system/user/getTgUserSQList", 
		method: "post",
		data: data  
	});
}

//[58]推广会员注册情况列表 1018
export function getTgUserList(data){
	return request({
		url: "/weituitui/system/user/getTgUserList", 
		method: "post",
		data: data  
	});
}

//[57]雇主通过稿件id联系提交人发送站内信 1015
export function taskUserMessageInsert(data){
	return request({
		url: "/weituitui/system/task/taskUserMessageInsert", 
		method: "post",
		data: data  
	});
}

//[56]通过稿件id查看自己的稿件内容和审核信息 1014
export function getMyTaskInfoTxt(data){
	return request({
		url: "/weituitui/system/task/getMyTaskInfoTxt", 
		method: "post",
		data: data  
	});
}

//[55]雇主评标bu合格 1014
export function auditorNo(data){
	return request({
		url: "/weituitui/system/task/auditorNo", 
		method: "post",
		data: data  
	});
}

//[54]雇主评标合格 1013
export function auditor(data){
	return request({
		url: "/weituitui/system/task/auditor", 
		method: "post",
		data: data  
	});
}


//[53]获取稿件状态以及稿件内容和发信信息 1013
export function getTaskInfoTxt(data){
	return request({
		url: "/weituitui/system/task/getTaskInfoTxt", 
		method: "post",
		data: data  
	});
}


//[52]获取首页最新消息100条数据  1006
export function getIndexTaskListData(){
	return request({
		url: "/task/pc/getIndexTaskListData", 
		method: "post"
	});
}

//[51]客户端消息列表数据  1006
export function getMessageList(data){
	return request({
		url: "/task/pc/getMessageList", 
		method: "post",
		data: data  
	});
}

//[50] 消息帖子提交 1005
export function setMessage(data){
	return request({
		url: "/task/pc/setMessage", 
		method: "post",
		data: data  
	});
}

//[49]获取消息分类所有数据1005   
export function getMessageTypeList(){
	return request({
		url: "/task/pc/getMessageTypeList", 
		method: "post"
	});
}

//[48]获取首页今日动态数据的数据1005
export function getTodyMsgList(){
	return request({
		url: "/task/pc/getTodyMsgList", 
		method: "post"
	});
}

//[47]首页面获取banner的数据1004
export function getBanner(){
	return request({
		url: "/task/pc/getBanner", 
		method: "post"
	});
}

//[46]获取任务最新提交的报名数据1001
export function getTaskNewDataRegeditList(data){
	return request({
		url: "/task/pc/getTaskNewDataRegeditList", 
		method: "post",
		data: data  
	});
}
//[45]获取任务最新提交的数据1001
export function getTaskNewDataList(data){
	return request({
		url: "/task/pc/getTaskNewDataList", 
		method: "post",
		data: data  
	});
}
 
//[44]客户端登录信息2条数据 0930
export function getUserLoginInfo(){
	return request({
		url: "/weituitui/system/user/getUserLoginInfo", 
		method: "post"
	});
}

//[43]设置用户昵称0930
export function setNickname(data){
	return request({
		url: "/weituitui/system/user/setNickname", 
		method: "post",
		data: data  
	});
}

//[42]设置用户图像0929
export function setAvatar(data){
	return request({
		url: "/weituitui/system/user/setAvatar", 
		method: "post",
		data: data  
	});
}
//[41]获取用户图像0929
export function getAvatar(){
	return request({
		url: "/weituitui/system/user/getAvatar", 
		method: "post"
	});
}

//[40]设置用户提现信息0928
export function setUserMoneyInfo(data){
	return request({
		url: "/weituitui/system/user/setUserMoneyInfo", 
		method: "post",
		data: data  
	});
}
//[39]获取用户提现信息0928
export function getUserMoneyInfo(){
	return request({
		url: "/weituitui/system/user/getUserMoneyInfo", 
		method: "post"
	});
}

//[38]财务中心列表0927
export function getBillList(data){
	return request({
		url: "/weituitui/system/user/getBillList", 
		method: "post",
		data: data  
	});
}

//[37] 获取余额和费率0921
export function getBill(){
	return request({
		url: "/weituitui/system/user/getBill", 
		method: "post"
	});
}

//[36] 用户提现列表0921
export function getUserBillList(data){
	return request({
		url: "/weituitui/system/user/getUserBillList", 
		method: "post",
		data: data  
	});
}
//[35] 用户提现0921
export function getUserBill(data){
	return request({
		url: "/weituitui/system/user/getUserBill", 
		method: "post",
		data: data  
	});
}

//[34] 获取用户余额和支出和收入0920
export function getUserMoney(){
	return request({
		url: "/weituitui/system/user/getUserMoney", 
		method: "post"
	});
}

//[33] 找回密码提交数据0920
export function retrievePassword(data){
	return request({
		url: "/retrievePassword", 
		method: "post", 
		data: data  
	});
}

//[32]找回密码  通过邮件地址发送验证码内容 返回uuid 有效期30分钟  0920
export function findPwd(data){
	return request({
		url: "/findPwd", 
		method: "post", 
		data: data  
	});
}

//[31] 修改密码0919
export function userUpdatePassword(data){
	return request({
		url: "/weituitui/system/task/userUpdatePassword", 
		method: "post", 
		data: data  
	});
}

//[30]任务详情页面回复站内信
export function taskMessageInsert(data){
	return request({
		url: "/weituitui/system/task/taskMessageInsert", 
		method: "post", 
		data: data  
	});
}

//[29] 站内信收件箱回复站内信0914  
export function messageInsert(data){
	return request({
		url: "/weituitui/system/task/messageInsert", 
		method: "post", 
		data: data  
	});
}


//[28]删除站内信和永久删除站内信 0914
export function deleteUserMessage(data){
	return request({
		url: "/weituitui/system/task/deleteUserMessage", 
		method: "post", 
		data: data  
	});
}

//[27]站内信列表 0914
export function getUserMessageList(data){
	return request({
		url: "/weituitui/system/task/getUserMessageList", 
		method: "post", 
		data: data  
	});
}

//[26]我发布的任务列表各个状态的数量0913
export function getListCounts(){
	return request({
		url: "/weituitui/system/task/getListCounts", 
		method: "post"
	});
}

//[26]我参与的任务列表各个状态的数量0913
export function getMyTaskListCountList(){
	return request({
		url: "/weituitui/system/task/getMyTaskListCountList", 
		method: "post"
	});
}

//[27]我发布的任务需要评标的稿件  0912
export function getMyTaskList(data){
	return request({
		url: "/weituitui/system/task/getMyTaskList", 
		method: "post", 
		data: data  
	});
}

//[26]我参与的任务列表各个状态的数量0911
export function getCounts(){
	return request({
		url: "/weituitui/system/task/getCounts", 
		method: "post"
	});
}

//[25]我参与的任务列表0911
export function getTaskDataList(data){
	return request({
		url: "/weituitui/system/task/getTaskDataList", 
		method: "post", 
		data: data  
	});
}

//[24]任务提交0909
export function insertTaskData(data){
	return request({
		url: "/weituitui/system/task/insertTaskData", 
		method: "post", 
		data: data  
	});
}

//[23]报名列表里面重新报名操作0908
export function signUpTask(data){
	return request({
		url: "/weituitui/system/task/signUpTask", 
		method: "post", 
		data: data  
	});
}

//[22]报名列表里面的取消0908
export function exitTaskRegister(data){
	return request({
		url: "/weituitui/system/task/exitTaskRegister", 
		method: "post", 
		data: data  
	});
}

//[21]获取报名列表信息0907
export function getRegistrationList(data){
	return request({
		url: "/weituitui/system/task/getRegistrationList", 
		method: "post", 
		data: data  
	});
}

//[20]任务详细信息里面的获取任务报名状态0907
export function taskSignUpIs(data){
	return request({
		url: "/task/pc/taskSignUpIs", 
		method: "post", 
		data: data  
	});
}

//[19]任务详细信息里面的任务报名0907
export function taskSignUp(data){
	return request({
		url: "/weituitui/system/task/taskSignUp", 
		method: "post", 
		data: data  
	});
}

//[18]任务列表里面的详情接口
export function getTaskItem(id){
	return request({
		url: "/task/pc/getTaskItem/"+id, 
		method: "get"
	});
}

//[17]任务大厅里面获取任务分类数据0827
export function getPcTaskTypeList(data){
	return request({
		url: "/task/pc/getTaskTypeList", 
		method: "post", 
		data: data  
	});
}

//[16]任务大厅列表数据0827
export function getIndexTaskList(data){
	return request({
		url: "/task/pc/getIndexTaskList", 
		method: "post", 
		data: data  
	});
}

//[15] 任务操作拒绝填写原因0827
export function taskReject(data){
	return request({
		url: "/weituitui/system/task/taskReject", 
		method: "post", 
		data: data  
	});
}

//[14] 任务到期时间终止0827
export function taskEndTime(data){
	return request({
		url: "/weituitui/system/task/taskEndTime", 
		method: "post", 
		data: data  
	});
}

//[13] 任务审核通过操作0827
export function taskPass(data){
	return request({
		url: "/weituitui/system/task/taskPass", 
		method: "post", 
		data: data  
	});
}

//[12]任务审核列表202208271208
export function getAdminTaskList(data){
	return request({
		url: "/weituitui/system/task/getAdminTaskList", 
		method: "post", 
		data: data  
	});
}

//[11]我发布的任务列表20220826213359
export function getTaskList(data){
	return request({
		url: "/weituitui/system/task/getTaskList", 
		method: "post", 
		data: data  
	});
}

//[10]发布任务
export function setTask(data){
	return request({
		url: "/weituitui/system/task/setTask", 
		method: "post", 
		data: data  
	});
}

//[9] 获取任务分类数据集合
export function getTaskTypeList(data) {
 	return request({
 		url: "/weituitui/system/task/getTaskTypeList", 
 		method: "post", 
 		data: data  
 	});
}

//[8] 获取用户认证信息
export function getUserInfo(data) {
 	return request({
 		url: "/weituitui/system/user/getUserInfo", 
 		method: "post", 
 		data: data  
 	});
}

//[7]手机号码认证
export function setUserPhoneAuthentication(data) {
 	return request({
 		url: "/weituitui/system/user/setUserPhoneAuthentication", 
 		method: "post", 
 		data: data  
 	});
}

//[6]用户邮箱认证
export function setUserEmailAuthentication(data) {
 	return request({
 		url: "/weituitui/system/user/setUserEmailAuthentication", 
 		method: "post", 
 		data: data  
 	});
}

//[5] 用户实名认证提交资料---setUserAuthentication
export function setUserAuthentication(data) {
 	return request({
 		url: "/weituitui/system/user/setUserAuthentication", 
 		method: "post", 
 		data: data  
 	});
}
 
 //[4]上传图片editorwang
export function uploadPictures(data) {
 	return request({
 		url: "/weituitui/system/task/uploadPictures", 
 		method: "post", 
 		data: data  
 	});
}
 
 //[3]pc端登录
export function pcLogin(data) {
 	return request({
 		url: "/pcLogin",//这个地址是去掉公共地址和私有域名之后剩下的地址
 		method: "post",//请求方式 支持多种方式  get post put delete 等等
 		data: data //发送请求要配置的参数 无参数的情况下也可以不写
 	});
 }
//[2]pc端注册账号
export function setPcRegister(data) {
	return request({
		url: "/pcRegister",//这个地址是去掉公共地址和私有域名之后剩下的地址
		method: "post",//请求方式 支持多种方式  get post put delete 等等
		data: data //发送请求要配置的参数 无参数的情况下也可以不写
	});
}

//[1] 获取验证码
export function getCaptchaImage(data) {
	return request({
		url: "/pcCaptchaImage",//这个地址是去掉公共地址和私有域名之后剩下的地址
		method: "get",//请求方式 支持多种方式  get post put delete 等等
		params: data //发送请求要配置的参数 无参数的情况下也可以不写
	});
}

